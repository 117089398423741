import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {reactLocalStorage} from 'reactjs-localstorage';

import { setToken, removeToken } from "./store/authSlice";

import AppLayout from "./layouts/AppLayout";
import AuthLayout from "./layouts/AuthLayout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Product from "./pages/Product";
import Profile from "./pages/Profile";
import Order from "./pages/Order";
import ProductReview from "./pages/ProductReview";
import Payout from "./pages/Payout";
import PayoutCreate from "./pages/PayoutCreate"
import PayoutHistoryCreate from "./pages/PayoutHistoryCreate";
import PayoutHistory from "./pages/PayoutHistory";

const AuthLayoutRoute = ({component: Component, ...rest}) => {
  
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthLayout>
          <Component {...matchProps}/>
        </AuthLayout>
      )}
    />
  );
}

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  );
};

function App() {

  // axios.defaults.baseURL = 'http://fashion-field.test/api/vendor'
  axios.defaults.baseURL = 'https://live.fashionfield.viserx.net/api/vendor'

  const { token } = useSelector((state) => state.auth)
  const dispatch = useDispatch();

 React.useEffect(() => {
   if(reactLocalStorage.get('_token', true) != null)
   {
      dispatch(setToken(reactLocalStorage.get('_token', true)))
   }
 }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="signin" />
        </Route>

        

        <AuthLayoutRoute path='/signin' component={Login} />
        <AppLayoutRoute path="/dashboard" component={Home} />
        <AppLayoutRoute path="/products" component={Product} />
        <AppLayoutRoute path="/orders" component={Order} />
        <AppLayoutRoute path="/reviews" component={ProductReview} />
        <AppLayoutRoute path="/profile" component={Profile} />
        <AppLayoutRoute path="/payout" component={Payout} />
        <AppLayoutRoute exact path="/payoutcreate" component={PayoutCreate}/>
        <AppLayoutRoute exact path="/payouthistory" component={PayoutHistory}/>
        <AppLayoutRoute exact path="/payouthistorycreate" component={PayoutHistoryCreate}/>
      </Switch>
    </Router>
  );
}

export default App;
